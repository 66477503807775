import { render, staticRenderFns } from "./PageFittingGuide.vue?vue&type=template&id=6c39694b&"
import script from "./PageFittingGuide.js?vue&type=script&lang=js&"
export * from "./PageFittingGuide.js?vue&type=script&lang=js&"
import style0 from "./PageFittingGuide.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PageFittingGuide.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fvirtshop%2Fcomponents%2Fcontent%2FPageFittingGuide%2FPageFittingGuide.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./PageFittingGuide.lang.sk.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fvirtshop%2Fcomponents%2Fcontent%2FPageFittingGuide%2FPageFittingGuide.vue&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports