import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import Divider from '@theme/components/molecule/Divider'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'

export default {
  mixins: [ImageUrlMixin, SeoMixin],
  components: {
    AppTitle,
    Breadcrumbs,
    Divider,
    LayoutBase,
    ProductsVisited,
    UniqueSellingPoints,
  },
  props: {
    displayedSectionCode: String,
  },
  head() {
    return {
      title: this.seoTitleSuffix(this.$t('PageFittingGuide.seo.title')),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('PageFittingGuide.seo.description', { website: this.$t('globals.website') }),
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$t('PageFittingGuide.seo.keywords', { website: this.$t('globals.website') }),
        },
        {
          property: 'og:description',
          content: this.$t('PageFittingGuide.seo.description', { website: this.$t('globals.website') }),
        },
        {
          property: 'og:image',
          content: this.imageUrl({
            src: this.$store.state.globals.meta.seo_image,
          }),
        },
        { property: 'og:site_name', content: this.$store.state.globals.meta.seo_title },
        { property: 'og:title', content: this.seoTitleSuffix(this.$t('PageFittingGuide.seo.title')) },
      ],
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('PageFittingGuide.breadcrumbs.index'),
          path: this.$route.path,
        },
      ],
      menu: [
        {
          id: this.$t('PageFittingGuide.section.underwearMen.code'),
          title: this.$t('PageFittingGuide.section.underwearMen.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.underwearMenComparison.code'),
          title: this.$t('PageFittingGuide.section.underwearMenComparison.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.underwearWomen.code'),
          title: this.$t('PageFittingGuide.section.underwearWomen.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.bra.code'),
          title: this.$t('PageFittingGuide.section.bra.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.shirtMen.code'),
          title: this.$t('PageFittingGuide.section.shirtMen.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.shirtWomen.code'),
          title: this.$t('PageFittingGuide.section.shirtWomen.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.brandObsessive.code'),
          title: this.$t('PageFittingGuide.section.brandObsessive.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.brandPassion.code'),
          title: this.$t('PageFittingGuide.section.brandPassion.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.brandLormar.code'),
          title: this.$t('PageFittingGuide.section.brandLormar.title'),
        },
        {
          id: this.$t('PageFittingGuide.section.fashionForWomen.code'),
          title: this.$t('PageFittingGuide.section.fashionForWomen.title'),
        },
      ],
    }
  },
  methods: {
    imagePath(imageName) {
      return imageName ? `/assets/images/${this.$t('PageFittingGuide.imagesDirectoryName')}/${imageName}` : false
    },
    scrollTo(event, id) {
      if (!event.metaKey) {
        event.stopPropagation()
        event.preventDefault()
        this.$scrollTo(`#${id}`)
        this.$router.push({ path: this.$router.path, hash: id })
      }
    },
  },
}
